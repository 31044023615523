import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import Axios from '../../utils/axiosInstance';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  MetalList: [],
  ClawList: [],
  DesignList: [],
  DesignnameList: [],
  DiamondList: [],
  FingerList: [],
  CenterstoneList: [],
  ShapeList: [],
  SideStoneList: [],
  categoryList: [],
  CertificateList: [],
  productList: [],
  ColourList: [],
  ClarityList: [],
  StyleList: [],
  ProfileList: [],
  TextureList: [],
  WeightList: [],
  SettingstoneList: [],
  PendantList: [],
  ChainList: [],
  SettingtypeList: [],
  EarringtypesList: [],
  ValuationList: [],
  ValTemplateList: [],
  FancyColorList: [],
  SearchData: [],
  product: null,
  designName: null
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteproduct(state, action) {
      const deleteproduct = filter(state.productList, (product) => product.id !== action.payload);
      state.productList = deleteproduct;
    },
    getMetalListSuccess(state, action) {
      state.isLoading = false;
      state.MetalList = action.payload;
    },
    getClawListSuccess(state, action) {
      state.isLoading = false;
      state.ClawList = action.payload;
    },
    getDesignListSuccess(state, action) {
      state.isLoading = false;
      state.DesignList = action.payload;
    },
    getDesignnameListSuccess(state, action) {
      state.isLoading = false;
      state.DesignnameList = action.payload;
    },
    getDiamondListSuccess(state, action) {
      state.isLoading = false;
      state.DiamondList = action.payload;
    },
    getFingerListSuccess(state, action) {
      state.isLoading = false;
      state.FingerList = action.payload;
    },
    getCenterstoneListSuccess(state, action) {
      state.isLoading = false;
      state.CenterstoneList = action.payload;
    },
    getShapeStoneListSuccess(state, action) {
      state.isLoading = false;
      state.ShapeList = action.payload;
    },
    getSideStoneListSuccess(state, action) {
      state.isLoading = false;
      state.SideStoneList = action.payload;
    },
    getCertificateListSuccess(state, action) {
      state.isLoading = false;
      state.CertificateList = action.payload;
    },
    createDesignName(state, action) {
      state.isLoading = false;
      state.designName = action.payload;
    },
    getCategoryListSuccess(state, action) {
      state.isLoading = false;
      state.categoryList = action.payload;
    },
    getProductListSuccess(state, action) {
      state.isLoading = false;
      state.productList = action.payload;
    },
    getColourListSuccess(state, action) {
      state.isLoading = false;
      state.ColourList = action.payload;
    },
    getClarityListSuccess(state, action) {
      state.isLoading = false;
      state.ClarityList = action.payload;
    },
    getStyleListSuccess(state, action) {
      state.isLoading = false;
      state.StyleList = action.payload;
    },
    getProfileListSuccess(state, action) {
      state.isLoading = false;
      state.ProfileList = action.payload;
    },
    getTextureListSuccess(state, action) {
      state.isLoading = false;
      state.TextureList = action.payload;
    },
    getWeightListSuccess(state, action) {
      state.isLoading = false;
      state.WeightList = action.payload;
    },
    getSettingstoneListSuccess(state, action) {
      state.isLoading = false;
      state.SettingstoneList = action.payload;
    },
    getPendanttypesListSuccess(state, action) {
      state.isLoading = false;
      state.PendantList = action.payload;
    },
    getChainListSuccess(state, action) {
      state.isLoading = false;
      state.ChainList = action.payload;
    },
    getSettingtypeListSuccess(state, action) {
      state.isLoading = false;
      state.SettingtypeList = action.payload;
    },
    getEarringtypesListSuccess(state, action) {
      state.isLoading = false;
      state.EarringtypesList = action.payload;
    },
    getValuationListSuccess(state, action) {
      state.isLoading = false;
      state.ValuationList = action.payload;
    },
    getValTemplateListSuccess(state, action) {
      state.isLoading = false;
      state.ValTemplateList = action.payload;
    },
    getFancyColorListSuccess(state, action) {
      state.isLoading = false;
      state.FancyColorList = action.payload;
    },
    getSearchDataListSuccess(state, action) {
      state.isLoading = false;
      state.SearchData = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteproduct } = slice.actions;

// ----------------------------------------------------------------------

export function getMetallist() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/metal-types');
      dispatch(slice.actions.getMetalListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClawlist() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/claw-types');
      dispatch(slice.actions.getClawListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDesignlist() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/design-types');
      dispatch(slice.actions.getDesignListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDesignNamelist() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/design-name');
      dispatch(slice.actions.getDesignnameListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDiamondlist() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/diamond-types');
      dispatch(slice.actions.getDiamondListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFingersizelist() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/finger-types');
      dispatch(slice.actions.getFingerListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCenterstonelist() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/stone-types');
      dispatch(slice.actions.getCenterstoneListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getShapeStonelist() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/stone_shape-types');
      dispatch(slice.actions.getShapeStoneListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSideStonelist() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/side-stone-types');
      dispatch(slice.actions.getSideStoneListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getCertificatelist() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/certificate-types');
      dispatch(slice.actions.getCertificateListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategoryList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/categories');
      dispatch(slice.actions.getCategoryListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/order-detail');
      dispatch(slice.actions.getProductListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getColourList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/colors');
      dispatch(slice.actions.getColourListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClarityList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/clarity');
      dispatch(slice.actions.getClarityListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getStyleList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/style');
      dispatch(slice.actions.getStyleListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProfileList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/profile');
      dispatch(slice.actions.getProfileListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTextureList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/texture');
      dispatch(slice.actions.getTextureListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getWeightList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/weight');
      dispatch(slice.actions.getWeightListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSettingstoneList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/setting-stone');
      dispatch(slice.actions.getSettingstoneListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPendanttypesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/pendant-types');
      dispatch(slice.actions.getPendanttypesListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getChainList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/chain');
      dispatch(slice.actions.getChainListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSettingtypeList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/setting-type');
      dispatch(slice.actions.getSettingtypeListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEarringtypesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/earring-types');
      dispatch(slice.actions.getEarringtypesListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFancyColorList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/diamond-color');
      dispatch(slice.actions.getFancyColorListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function dispatchNewProduct(product) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/order-detail', product);
      dispatch(slice.actions.createProduct(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function dispatchUpdateProduct(product) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/order-detail/${product.id}`, product);
      dispatch(slice.actions.createProduct(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function dispatchDeleteProduct(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await Axios.delete(`/order-detail/${productId}`);
      dispatch(slice.actions.deleteproduct(productId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function dispatchCreateDesignName(designName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/design-name', designName);
      dispatch(slice.actions.createDesignName(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// remove update  dispatchUpdateDesignName
export function dispatchUpdateDesignName(designName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/design-name/${designName.id}`, designName);
      dispatch(slice.actions.createDesignName(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getValuationList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/value-template');
      dispatch(slice.actions.getValuationListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getValTemplateList(selectedValuationId, proID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/value-template/${selectedValuationId}/${proID}`);
      dispatch(slice.actions.getValTemplateListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -------------------------- Stock/ Appro Searchable  ------------------------------------------

export function getSearchDataList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/search');
      dispatch(slice.actions.getSearchDataListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
