import { createSlice } from '@reduxjs/toolkit';
import Axios from '../../utils/axiosInstance';

const initialState = {
  DiamondList: [],
  GemstoneList: [],
  diamondId: [],
  gemstoneId: [],
  diamond: null,
  gemstone: null,
  isLoading: false,
  error: null
};

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // INVENTORY DIAMONDS
    createDiamondInventory(state, action) {
      state.isLoading = false;
      state.diamond = action.payload;
    },
    inventoryDiamondList(state, action) {
      state.isLoading = false;
      state.DiamondList = action.payload;
    },
    diamondById(state, action) {
      state.isLoading = false;
      state.diamondId = action.payload;
    },
    // INVENTORY GEMSTONES
    createGemstoneInventory(state, action) {
      state.isLoading = false;
      state.gemstone = action.payload;
    },
    inventoryGemstoneList(state, action) {
      state.isLoading = false;
      state.GemstoneList = action.payload;
    },
    gemstoneById(state, action) {
      state.isLoading = false;
      state.gemstoneId = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { DiamondList, diamond, deleteMasterCosts, createmasterCosts } = slice.actions;

// ----------------------------------  Create Inventory Diamond ------------------------------------

export function CreateDiamondInventory(diamond) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory-diamonds', diamond);
      return dispatch(slice.actions.createDiamondInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Inventory Diamond ------------------------------------

export function UpdateDiamondInventory(diamond) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory-diamonds/${diamond.id}`, diamond);
      return dispatch(slice.actions.createDiamondInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------- Inventory Diamond List -------------------------------------

export function getInventoryDiamond() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory-diamonds');

      dispatch(slice.actions.inventoryDiamondList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Diamond Id wise List -------------------------------------

export function getDiamondById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory-diamonds/${id}`);
      dispatch(slice.actions.diamondById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------  Create Inventory Gemstone ------------------------------------

export function CreateGemstoneInventory(gemstone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/inventory_gemstones', gemstone);
      return dispatch(slice.actions.createGemstoneInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Inventory Gemstone ------------------------------------

export function UpdateGemstoneInventory(gemstone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/inventory_gemstones/${gemstone.id}`, gemstone);
      return dispatch(slice.actions.createGemstoneInventory(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------- Inventory Gemstone List -------------------------------------

export function getInventoryGemstone() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/inventory_gemstones');

      dispatch(slice.actions.inventoryGemstoneList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------- Inventory Gemstone Id wise List -------------------------------------

export function getGemstoneById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/inventory_gemstones/${id}`);
      dispatch(slice.actions.gemstoneById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}